module.exports = {
  "Обязательное поле": "Міндетті жол",
  "Значение слишком короткое": "Мазмұны тым қысқа",
  "Значение слишком длинное": "Мазмұны тым ұзын",
  "Некорректный E-mail": "Қате E-mail",
  "Некорректный адрес сайта": "Сайттың мекенжайыңыз дұрыс емес",
  "Некорректные координаты": "Дұрыс емес координаттар",
  "Неправильный формат": "Қате формат",
  "Минимальное допустимое значение": "Минималды рұқсат етілген мән",
  "Максимальное допустимое значение": "Максималды рұқсат етілген мән",
  Время: "Уақыт",
  "Выберите ЭЦП": "ЭЦҚ таңдаңыз",
  "Введите пароль": "Құпиясөз енгізіңіз",
  "Выберите файл": "Файлды таңдаңыз",
  Ошибка: "Қате",
  "Размер файла не должен быть больше": "Файл өлшемі үлкен болмауы керек",
  "Количество загружаемого файла не должно быль больше": "Жүктелетін файлдың саны артық болмауы керек",
  Выбрать: "Таңдау",
  Выход: "Шығу",
  Места: "Орындар",
  "Председатель комиссии": "Комиссия төрағасы",
  Поставщики: "Ұйымдар",
  "Добавить председателя комиссии": "Комиссия төрағасын қосу",
  Редактировать: "Өңдеу",
  Сохранить: "Сақтау",
  "Скачать файл": "Файлды жүктеу",
  Посмотреть: "Көру",
  "Добавить новое место": "Жаңа орын қосу",
  "Отдел образования": "Білім бөлімі",
  "Организация образования": "Білім беру ұйымы",
  Пароль: "Құпиясөз",
  "Данные организации": "Ұйым деректері",
  "Объявленные места": "Жарияланған орындар",
  "Загрузить файл": "Файлды жүктеу",
  Удалить: "Жою",
  "Удалить файла": "Файлды жою",
  "Вы действительно хотите удалить этот файл?": "Сіз бұл файлды шынымен жойғыңыз келе ме?",
  Отмена: "Болдырмау",
  ОК: "ОК",
  "Вы действительно хотите удалить эту запись?": "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
  Объекты: "Нысандар",
  Сотрудники: "Қызметкерлер",
  "Удалить запись": "Жазбаны жою",
  Местонахождение: "Орналасқан жері",
  Координаты: "Координаттары",
  "Председатели комиссии": "Комиссия төрағалары",
  // "": "",
  place: "Конкурс туралы хабарландыру",
  commision: "Комиссия төрағасы",
  organization: "Ұйымдар",
  apply: "Конкурсқа қатысушылар",
  plan: "Оқу жоспары",
  staff: "Қызметкерлер",
  branch: "Нысандар",
  training: "Жетілдіру курстары",
  "Образовательная программа": "Оқу жоспары",
  "Вход в кабинет": "Жүйеге кіру",

  "Объявление о конкурсе": "Конкурс туралы хабарландыру",
  Черновики: "Шимайлар",
  Опубликованные: "Жарияланған",
  Отозванные: "Кері қайтарып алынған",
  Архив: "Мұрағат",
  Новые: "Жаңа",
  Допущенные: "Өткізілген",
  "Не допущенные": "Өткізілмеген",
  "Данные устарели": "Деректер ескірген",
  Заблокированные: "Бұғатталған",
  "На рассмотрении": "Қарастырылуда",
  Поданные: "Тапсырған",
  Подано: "Жiберiлдi",
  Одобрено: "Мақұлданды",
  Отказано: "Қабылданбады",
  Отозвано: "Қайтарып алынды",
  Одобренные: "Мақұлданған",
  Отказанные: "Қабылданбады",
  "Группы госзаказ": "Мемлекеттік тапсырыс топтары",
  "Группы платные": "Ақылы топтар",
  Расписание: "Кесте",
  "Список учеников": "Оқушылар тізімі",
  "Курсы повышения": "Арттыру курстары",
  КТП: "КТЖ",
  Зарегистрировано: "Тіркелген",
  "Отказано в регистрации": "Тіркеуден бас тартылды",
  Войти: "Кіру",
  "Вход в кабинет Управление Образования": "Білім басқармасының кабинетіне кіру",
  "Вход в кабинет Отдел Образования": "Білім бөлімінің кабинетіне кіру",
  "Вход в кабинет Отдел Образования для комиссии": "Комиссия үшін білім бөлімінің кабинетіне кіру",
  "Вход в кабинет / Регистрация Организации": "Ұйым кабинетіне кіру/тіркелу",
  "Вход в кабинет / Регистрация Сотрудника Организации": "Ұйым қызметкері кабинетіне кіру/тіркелу",
  Главная: "Басты",
  "Организации образования": "Білім беру ұйымдары",
  "Секции, кружки и занятия": "Секциялар, үйірмелер және сабақтар",
  "Подать заявление": "Өтініш жіберу",
  Продолжить: "Жалғастыру",
  Сегодня: "Бүгін",
  "Доблировать на следующую неделю": "Келесі аптаға көшірме жасау",
  "Доблировать до конца обучения": "Оқу жылының соңына дейін көшірме жасау",
  Закрыть: "Жабу",
  "За неделю": "Апта ішінде",
  "За день": "Күніне",
  Проверить: "Тексеру",
  "Как это работает": "Бұл қалай жұмыс істейді",
  "С нами удобно": "Бізбен ыңғайлы",
  Руководитель: "Ұйым басшысы",
  "Управление Образования": "Білім басқармасы",
  Родитель: "Ата-ана",
  "Отдел Образования": "Білім бөлімі",
  Сотрудник: "Қызметкер",
  "Платформа для дополнительного образования": "Қосымша білім беруге арналған платформа",
  "Язык обучения": "Оқыту тілі",
  "Показатели посещаемости": "Қатысу көрсеткіштері",
  Дата: "Күні",
  "Добавить ребенка": "Баланы қосу",
  Создать: "Қосу",
  ФИО: "ТАӘ",
  Пол: "Жынысы",
  Мужской: "Ұл",
  Женский: "Қыз",
  "Дата рождения": "Туған күні",
  ИИН: "ЖСН",
  Дети: "Балалар",
  "Все курсы": "Барлық үйірмелер",
  "Художественные школы": "Көркемөнер мектептері",
  "Гос журнал": "Сынып журналы",
  "Мои заявки": "Менің өтініштерім",
  "Мои курсы / Журнал": "Менің үйірмелерім / Журнал",
  Акты: "Актілер",
  "Сертификат выдается:": "Сертификат беріле ме:",
  "Стоимость:": "Құны",
  Бесплатно: "Тегін",
  "Адрес:": "Мекен-жай:",
  "Статус:": "Мәртебесі:",
  "Тип:": "Типі",
  "Материальная техническая база": "Материалдық техникалық базасы",
  "Выигранные конкурсы": "Қабылданған конкурстар",

  Группы: "Үйірмелер",
  "Гос Заявки": "Класс өтініштері",
  "Гос Журналы": "Класс журналдары",

  "Праздничные дни": "Мерекелік күндер",
  Заявки: "Оқыту өтінімдері",
  Журналы: "Журналдар",
  Замечания: "Ескертулер",
  "Распределение по группам": "Топқа бөлу",
  Профиль: "Профиль",
  "Активный профиль": "Белсенді профиль",
  Экзамены: "Емтихан",
  "Журнал инструктажа": "Нұсқаулық журналы",
  Табели: "Табель",
  "Достижения обучающихся": "Оқушылардың жетістіктері",
  "Журнал учета работы педагога": "Мұғалімнің жұмыс журналы",
  "Учебная нагрузка педагога": "Педагогтың оқу жүктемесі",
  Объект: "Нысан",
  "Сведения о детях": "Балалар туралы ақпарат",
  Организации: "Ұйымдар",
  "К вашему аккаунту не привязаны дети. Обратитесь к администратору школы.": "Сіздің аккаунтыңызға бала тіркелмеген. Мектеп әкімшілігімен байланысыңыз.",
  Нет: "Жоқ",
  Да: "Иә",
  "Мангистауская область": "Маңғыстау облысы",
  "Актау Г.А.": "Ақтау Қ.А.",
  "Жанаозен Г.А.": "Жаңаөзен Қ.А.",
  "Каракиянский район": "Қарақия ауданы",
  "Мангистауский район": "Маңғыстау ауданы",
  "Мунайлинский район": "Мұнайлы ауданы",
  "Тупкараганский район": "Түпқараған ауданы",
  "Бейнеуский район": "Бейнеу ауданды",
  Все: "Барлық",
  Поиск: "Іздеу",
  "По направлению курса": "Курс бағыты бойынша",
  "По региону курса": "Аймақ бойынша",

  Уведомление: "Хабарландыру",
  Уважаемый: "Құрметті",
  "cообщаем Вам, что Ваш ребенок": "cіздің балаңыз",
  "отчислен из группы": "топтан шығарылған - ",
  "Отчисление произведено": "Аударым жасалды",
  "С уважением": "Құрметпен",
  Ознакомился: "Мен таныстым",

  "Уважаемые пользователи платформы!": "Құрметті платформа пайдаланушылары!",
  "Обращаем ваше внимание на необходимость отправки всех технических запросов, связанных с платформой, на электронный адрес support.edus.kz. Пожалуйста, при составлении письма выберите по какому проекту пишете, укажите тему, опишите суть проблемы. Все запросы подлежат автоматической предварительной обработке перед передачей операторам. Поэтому, без правильно сформулированного, рассмотрение будет невозможным. Срок ответа составляет до 5 рабочих дней. Спасибо за понимание.":
    "Біз барлық техникалық жіберу қажеттілігіне назар аударамыз электрондық пошта арқылы платформаға қатысты сұраулар support.edus.kz. Хат жазғанда, қай жоба туралы жазып жатқаныңызды таңдаңыз, тақырыпты көрсету, мәселенің мәнін сипаттау. Барлық сұраулар автоматты түрде орындалады операторларға беру алдында алдын ала өңдеу. Сондықтан, онсыз дұрыс тұжырымдалса, қарастыру мүмкін болмайды. Жауап беру уақыты 5 жұмыс күніне дейін. Назарларыңызға рахмет.",

  "Гос организации": "Мемлекеттік ұйымдар",
  "Художественные школы (Дворец школьников)": "Өнер мектептері (Оқушылар сарайы)",
  "Дополнительные клубы": "Аула клубтары",
  Классы: "Класстар",
  "Журнал учета работы": "Жұмысты есепке алу журналы",

  "Удостоверение личности РК": "ҚР Жеке куәлігі",
  "Паспорт РК": "ҚР Паспорты",
  "ВНЖ РК": "ҚР ШТЫ",
  "МВД РК": "ҚР ІІМ",
  "МЮ РК": "ҚР ӘМ",
  "Орган выдачи": "Берген орган",
  "Тип документа": "Құжат түрі",

  "Поиск по карте": "Карта бойынша іздеу",
  Заявление: "Өтініш",
  "Прошу уведомлять меня об изменениях моего заявления следующими способами:": "Өтінішімнің өзгеруі туралы маған келесі тәсілдермен хабарлаңыз:",
  "электронное смс (sms) - уведомление в произвольной форме на следующие номера мобильных телефонов (не более двух номеров):":
    "электронды смс (sms) - келесі ұялы телефон нөмірлеріне еркін түрде хабарлама (екі нөмірден аспауы керек):",
  "электронные e-mail уведомления в произвольной форме:": "еркін нысандағы хабарламалар электрондық поштаға:",
  "Согласие на обработку персональных данных": "Дербес деректерге қол жеткізуге келісімі",
  
  "Только для поставщиков!" : "Тек қана ұйымдар үшін!",
  "Уважаемые пользователи! Уведомляем вас, что с 1 августа 2024 года платформа дополнительного образования Zerek EDUS начинает переход на годовое платное обслуживание, предоставляемое на договорной основе для частных поставщиков. Просим вас связаться с ответственным менеджером проекта Zerek EDUS для получения дополнительной информации." : "Құрметті қолданушылар! Zerek EDUS қосымша білімге арналған платформасы 1-ші тамыз 2024 ж. бастап жеке меншік білім орталықтарымен келісім-шарт түріндегі жылдық ақылы қызмет көрсету түріне көшуді бастайтындығын хабарлаймыз. Толық ақпарат алу үшін Zerek EDUS жоба менеджерімен байланысуыңызды сұраймыз.",
  "Здравствуйте! Хотел бы обратиться по вопросу платного обслуживания Zerek EDUS для получения дополнительной информации." : "Сәлеметсіз бе! Zerek EDUS қосымшасы бойынша ақылы қызмет көрсетуге көшу туралы толығырақ ақпарат білу мақсатында хабарласып тұрмын.",
  "Написать" : "Байланыс"
};
